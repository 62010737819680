import '../styles/style.scss';
import React from 'react';
import Parallax from '../components/Parallax';
import {
  motion,
} from "framer-motion";

export default function Home(){
    const s1 = '<';
    const s2 = '>';
    const s3 = '/';
    const y = Parallax(500);

    return(
        <section className='home' id='Home'>
            <span className='txt'>Hey, I’m </span>
            <h1 className='name-for-hover'>Bagher Hosseini</h1>
            <span className='txt-description'>I am a Fullstack web developer</span>

            <motion.h1 style={{  y }} className='first-object'><span className='objectAnimation'>{s1}</span></motion.h1>
            <motion.h1 style={{  y }} className='second-object'><span className='objectAnimation'>{s2}</span></motion.h1>

            <motion.span style={{ y }} className='random-shapes'><span className='randomShapeAnimation'>{s3}</span></motion.span>
            <motion.span style={{ y }} className='random-shapes'><span className='randomShapeAnimation'>{s3}</span></motion.span>
            <motion.span style={{ y }} className='random-shapes'><span className='randomShapeAnimation'>{s3}</span></motion.span>
            <motion.span style={{ y }} className='random-shapes'><span className='randomShapeAnimation'>{s3}</span></motion.span>
            <motion.span style={{ y }} className='random-shapes'><span className='randomShapeAnimation'>{s3}</span></motion.span>
            <motion.span style={{ y }} className='random-shapes'><span className='randomShapeAnimation'>{s3}</span></motion.span>
            <motion.span style={{ y }} className='random-shapes'><span className='randomShapeAnimation'>{s3}</span></motion.span>
            <motion.span style={{ y }} className='random-shapes'><span className='randomShapeAnimation'>{s3}</span></motion.span>
            <motion.span style={{ y }} className='random-shapes'><span className='randomShapeAnimation'>{s3}</span></motion.span>
            <motion.span style={{ y }} className='random-shapes'><span className='randomShapeAnimation'>{s3}</span></motion.span>
        </section>
    );
}