import '../styles/style.scss';

export default function Header(){
    return(
        <>
            <div className="logo">
                <span> Bagher </span>
            </div>
            <nav className="menu">
                <ul className="menu-list">
                    <li className='headerText'> <a href="#Home">Home</a> </li>
                    <li className='headerText'> <a href="#About">About</a> </li>
                    <li className='headerText'> <a href="#Project">Projects</a> </li>
                    <li className='headerText'> <a href="#Contact">Contact</a> </li>
                </ul>
            </nav>
        </>
    );
}