import './styles/style.scss'
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Project from './pages/Project';
import Contact from './pages/Contact';

function App() {
  return (
    <div className='allcontent'>
      <header>
        <Header/>
      </header>
      
      <main>
        <Home></Home>
        <About></About>
        <Project></Project>
      </main>

      <footer>
        <Contact></Contact>
      </footer>
    </div>
  );
}

export default App;
