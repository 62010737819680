
const about = {
    aboutMe : "I am a fullstack web developer with a passion for frontend development. I have a strong understanding of various web development technologies and am able to work on both the frontend and backend of a website. I have experience with HTML, CSS, JavaScript, React and other programming languages commonly used in web development. I enjoy creating visually appealing and user-friendly interfaces, and am always looking for ways to improve my skills and stay up to date with the latest trends in frontend development. ´Overall, I am a highly motivated and dedicated professional that is always eager to take on new challenges and learn new things.",

    frontend : ['HTML', 'CSS', 'SCSS', 'React JS', 'JavaScript'
    ],

    backend : ['php', 'JavaScript', 'C#'
    ],

    other : [
        'node JS', 'SQL', 'Bootstrap'
    ],
};

export default about;


