import '../styles/style.scss';

export default function Contact(){
    const currentYear = new Date().getFullYear();
    return(
        <section className='contact' id='Contact'>
            <div className='contact-content'>
                <div className='social-contact'>
                    <ul className='contact-list'>
                        <li><i className="fa fa-map-marker fa-2x"><span className="">Stockholm</span></i></li>
                        <li><i className="fa fa-envelope fa-2x"><span><a href="mailto:Bagherhosseini03@gmail.com" title="Send me an email">Email me</a></span></i></li>
                        <li><a href="https://github.com/bagherhosseini"> <i className="fa-brands fa-github"><span>Github</span></i></a></li>
                        <li><a href="https://www.linkedin.com/in/bagherhosseini/"> <i className="fa-brands fa-linkedin"><span>Linkedin</span></i></a></li>
                    </ul>
                </div>
                
                <p className='Copyright'>Copyright <span>&copy;</span> {currentYear} Bagher Hosseini</p>
            </div>
        </section>
    );
}