import Shoes from '../img/shoes.png';
import Weather from '../img/weather.PNG';
import Room from '../img/3droom.PNG';
import Twitter from '../img/twitter.png';
import Restaurant from '../img/restaurant.png';
import Chat from '../img/chat.png';
import todo from '../img/todo.PNG';


const projects = [
    { id: 1, name: 'Chat', lang:'Html & CSS & PHP', img: Chat},
    { id: 2, name: 'Restaurant', lang:'Html & SCSS', img: Restaurant, link:"https://restaurant-ba.netlify.app/"},
    { id: 3, name: 'Twitter', lang:'Html & SCSS', img: Twitter, link:"https://twitter-b.netlify.app/"},
    { id: 4, name: 'Todo', lang:'React, Node JS', img: todo},
    { id: 5, name: 'Shoes shop', lang:'Html & SCSS', img: Shoes, link:"https://shoes-shop-b.netlify.app/"},
    { id: 6, name: 'Weather', lang:'React & SCSS', img: Weather, link:"https://weather-b.netlify.app/"},
    { id: 7, name: '3D Room', lang:'React & SCSS', img: Room, link:"https://3d-room-1.netlify.app/"},
];

export default projects;