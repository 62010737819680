import React from 'react';
import '../styles/style.scss';
import about from '../data/about';
import { motion } from "framer-motion";
import Variant from '../components/Variant';
import aboutImg from '../img/b3.png'

export default function About(){

    return(
        <section className='about' id='About'>
            <div className='all-contant'>

             <motion.img className='about-img' src={aboutImg} alt="" width={400} 
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }} 
                variants={Variant('fadeLeft', 'spring', 1.5, 0.3)}
                />
                <div className='about-skill-info'>
                    <div className='about-container'>
                        <h1 className='title'>About</h1>
                        <motion.div 
                            className="about-box"
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true }} 
                            variants={Variant('fadeup', 'spring', 1, 0.1)}
                        >
                            <div className='line'>
                                <div className='about-info'>
                                    <br />
                                        {about.aboutMe}
                                    <br />
                                </div>
                            </div>
                        </motion.div>
                    </div>

                    <div className='skill-container'>
                        <h1 className='title'>Skills</h1>
                        <div className='all-skills'>
                            <motion.div 
                            className='forntend skill-box'
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true }} 
                            variants={Variant('fadeup', 'spring', 1, 0.2)}
                            >
                                <h3>Frontend</h3>
                                <div className='line'>
                                    <ul className='skill-list'>
                                        {about && about.frontend.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </motion.div>
                        
                            <motion.div className='backend skill-box'
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true }} 
                            variants={Variant('fadeup', 'spring', 1, 0.3)}
                            >
                                <h3>Backend</h3>
                                <div className='line'>
                                    <ul className='skill-list'>
                                        {about && about.backend.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </motion.div>
                            
                            <motion.div className='other skill-box'
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true }} 
                            variants={Variant('fadeup', 'spring', 1, 0.4)}
                            >
                                <h3>Other</h3>
                                <div className='line'>
                                    <ul className='skill-list'>
                                        {about && about.other.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </motion.div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}