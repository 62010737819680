export default function Variant(variant, type, duration, delay){
    switch (variant) {
        case 'fadeup':
            return(
                {offscreen: {
                    y: 100,
                    opacity: 0
                },
                onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                        delay:delay,
                        type: type,
                        bounce: 0.4,
                        duration: duration
                    }
                }}
            );
        case 'fadeRightImg':
            return(
                {offscreen: {
                    x: 100,
                    opacity: 0
                },
                onscreen: {
                    x: 0,
                    opacity: 1,
                    transition: {
                        delay:delay,
                        type: type,
                        duration: duration
                    }
                }}
            );
        case 'fadeRight':
            return(
                {offscreen: {
                    x: 100,
                    opacity: 0
                },
                onscreen: {
                    x: 0,
                    opacity: 1,
                    transition: {
                        delay:delay,
                        type: type
                    }
                }}
            );
        case 'fadeLeft':
            return(
                {offscreen: {
                    x: -100,
                    opacity: 0
                },
                onscreen: {
                    x: 0,
                    opacity: 1,
                    transition: {
                        delay:delay,
                        type: type
                    }
                }}
            );
        default:
            return;
    }
}