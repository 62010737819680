import '../styles/style.scss';
import projects from '../data/projectsdata';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";
import Variant from '../components/Variant';

const Item = ({ item, index }) => {

  const { ref, inView } = useInView({
    threshold: 0,
    root: null,
    rootMargin:"-50% -0% -47.5%"
  });

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let mobieProject = false;
  if(width <= 1024){
    mobieProject = true;
  }

  let oddOrEven = false;
  if(item.id % 2 === 0){
    oddOrEven = true;
  }

  return (
    <motion.a href={item.link} target="_blank" className = {inView & mobieProject ? 'projectEx' : 'projectCon'} ref={ref} key={item.id} initial="offscreen" whileInView="onscreen" viewport={{ once: true }} variants={Variant(oddOrEven ? 'fadeRight' : 'fadeLeft', 'tween', 0.5, 0.1)}>
      <div className='info'>
        <span className='pro-name'>{item.name}</span>
        <span className='pro-lang'>{item.lang}</span>
      </div>
      <div className='proj-img' style={{ backgroundImage: `url(${item.img})`}}></div>
      {
        item.link ? <span className='view'>Click to view</span> : <span></span>
      }
    </motion.a>
  );
};

const numProjects = projects.length * 250;

export default function Project(){
  return(
      <section className='project' id='Project'>
          <div className='all-content'>
              <h1>Projects</h1>
              <div className='projects'>
                <div className='project-list' style={{height: `${numProjects}px`}}>
                  {projects && projects.map((item, index) => (
                    <Item key={index} item={item} index={index}/>  
                  ))}
                </div>
              </div>
          </div>
      </section>
  );
}